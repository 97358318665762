
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93dmNA99l6i0Meta } from "/codebuild/output/src578737349/src/gx-booking/pages/[...slug].vue?macro=true";
import { default as _404D1byPrrDaIMeta } from "/codebuild/output/src578737349/src/gx-booking/pages/404.vue?macro=true";
import { default as _91_91filter_93_93ebqXPkjtpNMeta } from "/codebuild/output/src578737349/src/gx-booking/pages/account/bookings/[[filter]].vue?macro=true";
import { default as indexCgYBaGaCHoMeta } from "/codebuild/output/src578737349/src/gx-booking/pages/account/index.vue?macro=true";
import { default as notificationsW0nQNVencvMeta } from "/codebuild/output/src578737349/src/gx-booking/pages/account/notifications.vue?macro=true";
import { default as paymentsm2PooEn8BOMeta } from "/codebuild/output/src578737349/src/gx-booking/pages/account/payments.vue?macro=true";
import { default as personal_45infoWZJ2kXDWj2Meta } from "/codebuild/output/src578737349/src/gx-booking/pages/account/personal-info.vue?macro=true";
import { default as securityCiXILIK23WMeta } from "/codebuild/output/src578737349/src/gx-booking/pages/account/security.vue?macro=true";
import { default as confirm8y3jDl9QkQMeta } from "/codebuild/output/src578737349/src/gx-booking/pages/experiences/[parentId]/[serviceId]/confirm.vue?macro=true";
import { default as indexyC6mL5G9lIMeta } from "/codebuild/output/src578737349/src/gx-booking/pages/experiences/[parentId]/[serviceId]/index.vue?macro=true";
import { default as _91bookingId_93enVzbwnEyWMeta } from "/codebuild/output/src578737349/src/gx-booking/pages/experiences/[parentId]/[serviceId]/return/[bookingId].vue?macro=true";
import { default as _91accessKey_93kt028JIkvmMeta } from "/codebuild/output/src578737349/src/gx-booking/pages/gratuity/[accessKey].vue?macro=true";
import { default as loginuvEY7I79apMeta } from "/codebuild/output/src578737349/src/gx-booking/pages/login.vue?macro=true";
import { default as reset_45password0XuMfHpxkaMeta } from "/codebuild/output/src578737349/src/gx-booking/pages/reset-password.vue?macro=true";
import { default as set_45passwordIby5F4UyFpMeta } from "/codebuild/output/src578737349/src/gx-booking/pages/set-password.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93dmNA99l6i0Meta || {},
    component: () => import("/codebuild/output/src578737349/src/gx-booking/pages/[...slug].vue")
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/codebuild/output/src578737349/src/gx-booking/pages/404.vue")
  },
  {
    name: "account-bookings-filter",
    path: "/account/bookings/:filter?",
    meta: _91_91filter_93_93ebqXPkjtpNMeta || {},
    component: () => import("/codebuild/output/src578737349/src/gx-booking/pages/account/bookings/[[filter]].vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexCgYBaGaCHoMeta || {},
    component: () => import("/codebuild/output/src578737349/src/gx-booking/pages/account/index.vue")
  },
  {
    name: "account-notifications",
    path: "/account/notifications",
    meta: notificationsW0nQNVencvMeta || {},
    component: () => import("/codebuild/output/src578737349/src/gx-booking/pages/account/notifications.vue")
  },
  {
    name: "account-payments",
    path: "/account/payments",
    meta: paymentsm2PooEn8BOMeta || {},
    component: () => import("/codebuild/output/src578737349/src/gx-booking/pages/account/payments.vue")
  },
  {
    name: "account-personal-info",
    path: "/account/personal-info",
    meta: personal_45infoWZJ2kXDWj2Meta || {},
    component: () => import("/codebuild/output/src578737349/src/gx-booking/pages/account/personal-info.vue")
  },
  {
    name: "account-security",
    path: "/account/security",
    meta: securityCiXILIK23WMeta || {},
    component: () => import("/codebuild/output/src578737349/src/gx-booking/pages/account/security.vue")
  },
  {
    name: "experiences-parentId-serviceId-confirm",
    path: "/experiences/:parentId()/:serviceId()/confirm",
    meta: confirm8y3jDl9QkQMeta || {},
    component: () => import("/codebuild/output/src578737349/src/gx-booking/pages/experiences/[parentId]/[serviceId]/confirm.vue")
  },
  {
    name: "experiences-parentId-serviceId",
    path: "/experiences/:parentId()/:serviceId()",
    meta: indexyC6mL5G9lIMeta || {},
    component: () => import("/codebuild/output/src578737349/src/gx-booking/pages/experiences/[parentId]/[serviceId]/index.vue")
  },
  {
    name: "experiences-parentId-serviceId-return-bookingId",
    path: "/experiences/:parentId()/:serviceId()/return/:bookingId()",
    meta: _91bookingId_93enVzbwnEyWMeta || {},
    component: () => import("/codebuild/output/src578737349/src/gx-booking/pages/experiences/[parentId]/[serviceId]/return/[bookingId].vue")
  },
  {
    name: "gratuity-accessKey",
    path: "/gratuity/:accessKey()",
    meta: _91accessKey_93kt028JIkvmMeta || {},
    component: () => import("/codebuild/output/src578737349/src/gx-booking/pages/gratuity/[accessKey].vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginuvEY7I79apMeta || {},
    component: () => import("/codebuild/output/src578737349/src/gx-booking/pages/login.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password0XuMfHpxkaMeta || {},
    component: () => import("/codebuild/output/src578737349/src/gx-booking/pages/reset-password.vue")
  },
  {
    name: "set-password",
    path: "/set-password",
    meta: set_45passwordIby5F4UyFpMeta || {},
    component: () => import("/codebuild/output/src578737349/src/gx-booking/pages/set-password.vue")
  }
]